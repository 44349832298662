import FormSingleSelect from "../../../components/formComponents/FormSingleSelect";
import useGetUniquePolicyNamesByProduct from "../../../components/WorkloadStatusByNamespace/useGetUniquePolicyNamesByProduct";
import { ScaleOpsProduct } from "../../../utils/typesUtils";

interface Props {
  name: string;
  isCustomizedPolicy?: boolean;
  label?: string;
  className?: string;
}

const PolicyField = ({ isCustomizedPolicy, name, label, className }: Props) => {
  const uniquePolicyNamesByProduct = useGetUniquePolicyNamesByProduct({
    scaleOpsProduct: ScaleOpsProduct.Rightsize,
    excludeScheduledPolicies: true,
  });

  return (
    <FormSingleSelect
      name={name}
      options={uniquePolicyNamesByProduct?.map((name) => [name, name]) || []}
      areOptionsDisabled={!isCustomizedPolicy}
      label={label}
      className={className}
    />
  );
};
export default PolicyField;
