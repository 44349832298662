import { PodReasons } from "../../autoscalers/Utils";
import BlockedNodesFilter, { BlockedNodesFilterProps } from "./BlockedNodesFilter";

const FILTERS: BlockedNodesFilterProps[] = [
  {
    label: "Blocked annotation or PDB",
    paramsKeys: [
      PodReasons.NotSafeToEvictAnnotation,
      PodReasons.NotSafeToEvictLabel,
      PodReasons.PdbPreventingScaleDown,
    ],
  },
  {
    label: "Kube-system workloads without PDB",
    paramsKeys: [PodReasons.UnmovableKubeSystemPod],
  },
  {
    label: "Unhealthy workloads",
    paramsKeys: [PodReasons.PdbTemporaryBlockingScaleDown],
  },
  {
    label: "Pods without owner",
    paramsKeys: [PodReasons.PodWithNoOwner, PodReasons.PodOwnerReachedToMinimumReplicas],
  },
];

export const allFiltersQueryParams = [...new Set(FILTERS.flatMap((filter) => filter.paramsKeys))];

const BlockedNodesFiltersWrapper = () => {
  return (
    <div className="w-full flex gap-2 item flex-grow h-[58px]">
      {FILTERS.map((filter) => (
        <BlockedNodesFilter key={filter.label} {...filter} />
      ))}
    </div>
  );
};

export default BlockedNodesFiltersWrapper;
