import FormTitleAndDescription from "../../../components/formComponents/FormTitleAndDescription";
import PolicyField from "./PolicyField";

interface Props {
  isCustomizedPolicy?: boolean;
}

const DefaultPolicy = ({ isCustomizedPolicy }: Props) => {
  return (
    <div className="flex flex-col gap-4 border-b border-strongBorder pb-7">
      <FormTitleAndDescription
        title="Default policy"
        description="This policy will be applied in the absence of an override policies."
      />
      <PolicyField name="defaultPolicy" isCustomizedPolicy={isCustomizedPolicy} />
    </div>
  );
};
export default DefaultPolicy;
