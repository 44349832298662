import { useQuery } from "@tanstack/react-query";
import AlertBar, { AlertBarTheme } from "../AlertBar";
import { GetScaleopsPodsHealth, GetScaleopsPodsHealthResponse } from "../../api/fetcher";
import { ReactNode } from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { components } from "../../api/schema";

const { queryKey, queryFn } = GetScaleopsPodsHealth();

const IMAGE_MATCH_CHART_ENABLED = true;
export type HealthStatus = components["schemas"]["HealthStatus"];

const AlertCode = styled(Typography)(() => ({
  backgroundColor: "#f6f8fa",
  borderRadius: "6px",
  color: "black",
  padding: "4px",
  width: "fit-content",
  fontSize: "12px",
  display: "inline",
}));

const OverviewAlertBars = () => {
  const { data: healthStatus } = useQuery<GetScaleopsPodsHealthResponse, Error>({
    queryKey: [queryKey],
    queryFn: queryFn,
    refetchInterval: 1000 * 60,
  });

  const alertData: ReactNode[] = [];
  if (healthStatus?.isFreshInstall) {
    return null;
  }

  if (healthStatus?.prometheus?.healthy === false) {
    alertData.push(getPodStatusReason(healthStatus?.prometheus, "prometheus-server"));
  } else if (healthStatus?.admissionController?.healthy === false) {
    alertData.push(getPodStatusReason(healthStatus?.admissionController, "admissions"));
  } else if (healthStatus?.kubeStateMetrics?.healthy === false) {
    alertData.push(getPodStatusReason(healthStatus?.kubeStateMetrics, "kube-state-metrics"));
  }
  if (healthStatus?.updater?.healthy === false) {
    alertData.push(getPodStatusReason(healthStatus?.updater, "updater"));
  }
  if (healthStatus?.recommender?.healthy === false) {
    alertData.push(getPodStatusReason(healthStatus?.recommender, "recommender"));
  }
  if (healthStatus?.agentController?.healthy === false) {
    alertData.push(getPodStatusReason(healthStatus?.agentController, "agent"));
  }
  if (IMAGE_MATCH_CHART_ENABLED && healthStatus?.imageMatchesChart === false) {
    alertData.push(
      <>
        The <b>scaleops image doesn't match the chart version</b>. This can cause unexpected behavior. Please verify
        ScaleOps is installed correctly.
      </>
    );
  }
  let title = <>Health Check</>;

  if (healthStatus?.trialExpired === true) {
    title = <>⏳ Your ScaleOps trial period will expire soon!</>;
  }

  return (
    ((alertData.length || healthStatus?.trialExpired === true) && (
      <div className="sticky self-start top-0 z-50 w-full">
        <AlertBar title={title} description={alertData} theme={AlertBarTheme.LightRed} />
      </div>
    )) ||
    null
  );
};

type ReasonFunction = (status: HealthStatus, name: string) => React.ReactNode;

interface StatusToReasonMap {
  [key: string]: ReasonFunction;
}

const defaultStatusToReason = (_: HealthStatus, name: string) => (
  <>
    <b>scaleops-{name}</b> is unready, affecting ScaleOps performance.
  </>
);

const statusToReasonMap: StatusToReasonMap = {
  "Affinity Conflict": (_: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> is pending due to a volume node affinity conflict.
    </>
  ),
  CrashLoopBackOff: (_: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> is currently in CrashLoopBackOff state, affecting ScaleOps performance.
    </>
  ),
  "Component Disabled": (_: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> is currently offline as it was installed with the{" "}
      <AlertCode>{name}.enabled=false</AlertCode> flag. <br />
      To re-enable admission, reinstall ScaleOps with Helm flag <AlertCode>--set {name}.enabled=true</AlertCode>.
    </>
  ),
  "Missing Deployment": (_: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> deployment is missing, affecting ScaleOps performance.
    </>
  ),
  "Pod Optimization Disabled": (_: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> is unable to mutate pods, which cause recommendations not to be applied.
    </>
  ),
  "Prometheus Metrics Error": (_: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> is unable to fetch metrics, affecting ScaleOps performance.
    </>
  ),
  "Persistent Volume Was Deleted": (_: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> is stuck on ContainerCreating because volume was deleted.
    </>
  ),
  Pending: (_: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> is pending and unable to run, affecting ScaleOps performance.
    </>
  ),
  "Failed To Create Because Security Context Denied Host Path": (status: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> failed to create because security context denied host path.
    </>
  ),
  "Zero Pods": (_: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> pod doesn’t exist, affecting ScaleOps performance.
    </>
  ),
  "Unable To Load Config": (_: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> is failing because unable to load configuration.
    </>
  ),
  "Stuck On Container Creating": (_: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> is stuck on container creating.
    </>
  ),
  "Stuck On Container Terminating": (_: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> is stuck on container terminating.
    </>
  ),
  "Missing Mutating Webhook Configuration": (_: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> is missing mutating webhook configuration.
    </>
  ),
  "Health Check Pod Failed Because Blocked By OPA": (_: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> health check pod failed to create because blocked by OPA.
    </>
  ),
  "Failed to Create Pod Because Blocked By OPA": (_: HealthStatus, name: string) => (
    <>
        <b>scaleops-{name}</b> failed to create pod due to restrictions enforced by OPA.
    </>
  ),
  "Health Check Pod Failed Because Other Admissions": (status: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> health check pod failed to create because other admission{" "}
      <AlertCode>{status.type?.Message}</AlertCode> has <AlertCode>failurePolicy: Fail</AlertCode> policy.
    </>
  ),
  "Failed Scheduling": (_: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> failed to schedule.
    </>
  ),
  "Misconfigured Cert Manager": (_: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> misconfigured cert manager.
    </>
  ),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  "Mutating Webhook Unknown Service": (_: HealthStatus, _2: string) => (
    <>
      Mutating webhook <AlertCode>scaleops-mutating-webhook-configuration</AlertCode> linked to an unknown service.
    </>
  ),
  QueryError: (_: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> metrics are missing, this may affect recalculated recommendations and platform data.
    </>
  ),
  "Kube State Metrics Pod Metrics": (_: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> pod metrics are missing, this may affect recalculated recommendations and platform data.
    </>
  ),
  "Prometheus Failed To Scrape Target": (_: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> failed to scrape target <b>scaleops-{name}</b>.
    </>
  ),
  ErrImagePull: (_: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> is in ErrImagePull state.
    </>
  ),
  ImagePullBackOff: (_: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> is in ImagePullBackOff state.
    </>
  ),
  InvalidImageName: (_: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> is in InvalidImageName state.
    </>
  ),
  FailedCreatePodSandBox: (_: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> failed to create pod sandbox.
    </>
  ),
  UnsupportedContainerStorageInterfaceDriver: (status: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> does not support CSI <AlertCode>{status.type?.Message}</AlertCode>.
    </>
  ),
  FailedAttachVolume: (status: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> is pending because of failure to attach a volume. Please try a different storage class by
      installing with additional helm flag{" "}
      <AlertCode>
        --set prometheus.server.persistentVolume.storageClass=
        {status.type?.Message ? `<${status.type?.Message}>` : "<EXITING STORAGE CLASS>"}
      </AlertCode>
    </>
  ),
  NoStorageClass: (_: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> is pending because no storage class is detected. Please install scaleops with the
      additional helm flag <AlertCode>--set prometheus.emptyDir=true</AlertCode>
    </>
  ),
  NoMatchingStorageClass: (status: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> is pending because no matching storage class is configured. Please install ScaleOps with
      the additional helm flag{" "}
      <AlertCode>
        --set prometheus.server.persistentVolume.storageClass=
        {status.type?.Message ? `<${status.type?.Message}>` : "<EXITING STORAGE CLASS>"}
      </AlertCode>
    </>
  ),
  NoDefaultStorageClass: (status: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> is pending because no default storage class is configured. Please install ScaleOps with the
      additional helm flag{" "}
      <AlertCode>
        --set prometheus.server.persistentVolume.storageClass=
        {status.type?.Message ? `<${status.type?.Message}>` : "<EXITING STORAGE CLASS>"}
      </AlertCode>
    </>
  ),
  AttachmentIsBeingDeleted: (_: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> is pending because volume attachment is being deleted. Please verify ScaleOps is installed
      correctly.
    </>
  ),
  "Connection Error With Custom Network Policy": (_: HealthStatus, name: string) => (
    <>
      <b>scaleops-dashboard</b> is failing to establish a connection to <b>scaleops-{name}</b>.
    </>
  ),
  "Connection Error": (_: HealthStatus, name: string) => (
    <>
      <b>scaleops-dashboard</b> is failing to establish a connection to <b>scaleops-{name}</b>.
    </>
  ),
  "Persistent Volume Pending With No CSI": (_: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> cannot start, CSI driver missing from cluster.
    </>
  ),
  "Persistent Volume Pending With Failed Binding": (status: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> cannot start - storage creation failed, failed to bind storage. <br />
      Please try a different storage class by installing with additional helm flag{" "}
      <AlertCode>
        --set prometheus.server.persistentVolume.storageClass=
        {status.type?.Message ? `<${status.type?.Message}>` : "<EXITING STORAGE CLASS>"}
      </AlertCode>
    </>
  ),
  "Persistent Volume Pending With Cloud Timeout": (status: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> cannot start - storage creation failed, failed to provision storage. <br />
      Please try a different storage class by installing with additional helm flag{" "}
      <AlertCode>
        --set prometheus.server.persistentVolume.storageClass=
        {status.type?.Message ? `<${status.type?.Message}>` : "<EXITING STORAGE CLASS>"}
      </AlertCode>
    </>
  ),
  "Persistent Volume Pending With Cloud Failure": (status: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> cannot start - storage creation failed, failed to provision storage. <br />
      Please try a different storage class by installing with additional helm flag{" "}
      <AlertCode>
        --set prometheus.server.persistentVolume.storageClass=
        {status.type?.Message ? `<${status.type?.Message}>` : "<EXITING STORAGE CLASS>"}
      </AlertCode>
    </>
  ),
  "Persistent Volume Claim Lost": (_: HealthStatus, name: string) => (
    <>
      <b>scaleops-{name}</b> cannot start, persistent volume has been deleted. Please try to reinstall ScaleOps.
    </>
  ),
};

const getPodStatusReason = (status: HealthStatus, name: string) => {
  const statusToReason = status.type?.Status
    ? statusToReasonMap[status.type.Status] || defaultStatusToReason
    : defaultStatusToReason;
  return statusToReason(status, name);
};

export default OverviewAlertBars;
