import { ListItemText, MenuItem, Typography } from "@mui/material";
import clsx from "clsx";
import { FieldHookConfig, useField } from "formik";
import SingleSelect from "../SingleSelect";
import ErrorLabel from "./ErrorLabel";

const get24HoursArr = (arrayStartTime: string) => {
  const startTime = arrayStartTime.split(":");
  const startHour = parseInt(startTime[0]);
  const hoursArr = [];
  for (let i = 0; i < 24; i++) {
    const hour = (startHour + i) % 24;
    hoursArr.push(`${hour.toString().padStart(2, "0")}:00`);
  }
  return hoursArr;
};

type Props = {
  label?: React.ReactNode;
  className?: string;
  disabledOptions?: string[];
  areOptionsDisabled?: boolean;
  fullWidth?: boolean;
  additionalOnChange?: (value: string | number) => void;
  hourArrayStartTime?: string;
  showNetDayIndicator?: boolean;
} & FieldHookConfig<string | number> &
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const FormHourSelect = ({
  label,
  className,
  disabledOptions,
  areOptionsDisabled,
  fullWidth,
  additionalOnChange,
  hourArrayStartTime = "00:00",
  showNetDayIndicator,
  ...props
}: Props) => {
  const [field, meta] = useField(props);

  return (
    <>
      <div className={"flex flex-col gap-1 " + (fullWidth ? "w-full" : "w-fit") + "  relative"}>
        {label && (
          <Typography variant="body2" className="text-text-lightBlack flex items-center gap-1">
            {label}
          </Typography>
        )}
        <SingleSelect<string | number>
          selected={field.value}
          className={clsx(className, "h-[34px] w-[90px]")}
          setSelected={(value) => {
            field.onChange({
              target: {
                name: field.name,
                value,
              },
            });
            if (additionalOnChange) additionalOnChange(value);
          }}
          renderOptionsFunc={() =>
            get24HoursArr(hourArrayStartTime).map((hour) => {
              let displayLabel = <>{hour}</>;
              if (showNetDayIndicator && hour < hourArrayStartTime) {
                displayLabel = (
                  <div className="w-[120px] flex justify-between items-center">
                    <span>{hour}</span>
                    <Typography variant="caption" className="bg-text-lightBlack text-white px-2 rounded-full">
                      next day
                    </Typography>
                  </div>
                );
              }

              return (
                <MenuItem value={hour} key={hour} disabled={areOptionsDisabled || disabledOptions?.includes(hour)}>
                  <ListItemText primary={displayLabel} />
                </MenuItem>
              );
            })
          }
          disabled={!!props.disabled}
        />
        {meta.touched && meta.error ? <ErrorLabel label={meta.error} /> : null}
      </div>
    </>
  );
};

export default FormHourSelect;
