import { Typography } from "@mui/material";
import clsx from "clsx";
import { FieldArray, useFormikContext } from "formik";
import { useEffect } from "react";
import { components } from "../../../api/schema";
import FormCheckBox from "../../../components/formComponents/FormCheckBox";
import AddIcon from "../../../Icons/AddIcon";
import DeleteIcon from "../../../Icons/DeleteIcon";
import HistoryCollection, { HistoryCollectionType } from "./HistoryCollection";
import Period from "./Period";
import PolicyField from "./PolicyField";
import { DEFAULT_PERIOD } from "./useGetDefalutSchedulePolicy";

interface Props {
  index: number;
  remove: <T>(index: number) => T | undefined;
  numberOfRules: number;
  isCustomizedPolicy: boolean;
}

const Rule = ({ index, remove, numberOfRules, isCustomizedPolicy }: Props) => {
  const { values, setFieldValue } = useFormikContext<{
    rules?: components["schemas"]["V1alpha1SchedulePolicyRule"][];
  }>();

  useEffect(() => {
    if (!values.rules?.[index]) {
      setFieldValue(`rules[${index}]`, {
        policyName: "production",
        useRelativeHistoryData: HistoryCollectionType.Always,
        periods: [{ ...DEFAULT_PERIOD.weeklyConfig }],
      });
    }
  }, []);

  const isDeleteEnabled = isCustomizedPolicy && numberOfRules > 1;

  return (
    <div className="relative p-4 w-full flex flex-col bg-guideline-lightGray rounded-lg border border-border items-start gap-5">
      <div className="relative w-full flex items-start gap-2.5">
        <div>
          <PolicyField
            name={`rules[${index}].policyName`}
            label="PolicyName"
            isCustomizedPolicy={isCustomizedPolicy}
            className="bg-white"
          />
          <FormCheckBox
            name={`rules[${index}].sleepMode`}
            label={<Typography variant="caption">Sleep</Typography>}
            disableChange={!isCustomizedPolicy}
            disabled={!isCustomizedPolicy}
            className="mt-3"
          />
        </div>
        <HistoryCollection name={`rules[${index}].useRelativeHistoryData`} isCustomizedPolicy={isCustomizedPolicy} />
      </div>
      <div className="w-full flex flex-col gap-2.5">
        <Typography variant="body2" fontWeight={700}>
          Periods
        </Typography>
        <FieldArray name={`rules[${index}].periods`}>
          {({ push: pushPeriod, remove: removePeriod }) => (
            <div className="flex flex-col gap-3.5">
              {values.rules?.[index].periods?.map((_, periodIndex, allPeriods) => (
                <Period
                  index={periodIndex}
                  ruleIndex={index}
                  periodIndex={periodIndex}
                  namePrefix={`rules[${index}].periods[${periodIndex}]`}
                  remove={removePeriod}
                  numberOfPeriods={allPeriods.length}
                  isCustomizedPolicy={isCustomizedPolicy}
                />
              ))}
              <button
                type="button"
                onClick={() =>
                  pushPeriod({
                    ...DEFAULT_PERIOD,
                  })
                }
                className="flex justify-end"
              >
                <AddIcon width={14} height={14} />
              </button>
            </div>
          )}
        </FieldArray>
      </div>
      <DeleteIcon
        type="button"
        onClick={() => {
          if (isDeleteEnabled) remove(index);
        }}
        width={14}
        height={14}
        className={clsx("absolute top-1.5 right-1.5", {
          "cursor-not-allowed opacity-20": !isDeleteEnabled,
          "hover:opacity-80 cursor-pointer": isDeleteEnabled,
        })}
      />
    </div>
  );
};

export default Rule;
