import { Checkbox, ListItemText, MenuItem } from "@mui/material";
import { convertDayNumberToDayOfWeekLong } from "../../../components/DaySelection";
import FormMultiSelect from "../../../components/formComponents/FormMultiSelect";

const DAYS = ["0", "1", "2", "3", "4", "5", "6"];

interface Props {
  name: string;
}

const SelectDays = ({ name }: Props) => (
  <FormMultiSelect
    disableTooltip
    name={name}
    label="Days"
    options={DAYS}
    renderValue={(value) => {
      if (value.length === 7) {
        return "Every day";
      }
      return value.map((day) => convertDayNumberToDayOfWeekLong(Number(day))).join(", ");
    }}
    optionRenderFunction={(option: string, index: number, selected: (string | undefined)[], dataTestId?: string) => {
      return (
        <MenuItem
          value={Number(option)}
          key={`${index}-multi-select-option`}
          data-testid={dataTestId ? `${dataTestId}-option-${index}` : undefined}
          className="min-w-fit"
        >
          <div className="min-w-[235px] flex items-center">
            <Checkbox checked={selected.map((day) => Number(day)).includes(Number(option))} />
            <ListItemText primary={convertDayNumberToDayOfWeekLong(Number(option))} />
          </div>
        </MenuItem>
      );
    }}
  />
);

export default SelectDays;
