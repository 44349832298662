import { getNodeGroupInfoWithFilters } from "../../autoscalers/NodeGroups";
import NodesBreakdownTab, { Columns } from "../../autoscalers/NodesBreakdownTab";
import Tab from "../../Tab";
import BlockedNodesFiltersWrapper, { allFiltersQueryParams } from "./BlockedNodesFiltersWrapper";

const MANDATORY_COLUMNS = [
  Columns.Name,
  Columns.Cost,
  Columns.CpuRequestVsAllocatableDiff,
  Columns.MemoryRequestVsAllocatableDiff,
];

const BlockedNodes = () => {
  const { isError, error, data, isLoading } = getNodeGroupInfoWithFilters(allFiltersQueryParams);

  if (isError) {
    console.log("Error fetching node groups in blocked nodes", error);
  }

  return (
    <div className="flex flex-col w-full">
      <div className={"flex gap-[3px]"}>
        <Tab
          name="Live"
          isSelected={true}
          onClick={() => {
            return;
          }}
        />
      </div>
      <div className="p-4 pb-8 bg-white w-full rounded-lg rounded-tl-none">
        <NodesBreakdownTab
          isLoading={isLoading}
          nodeStats={data?.nodeStats || []}
          nodeGroups={data?.nodeGroups || []}
          customFiltersComponent={<BlockedNodesFiltersWrapper />}
          mandatoryColumns={[
            Columns.Name,
            Columns.Cost,
            Columns.CpuRequestVsAllocatableDiff,
            Columns.MemoryRequestVsAllocatableDiff,
          ]}
          initialSelectedColumns={[]}
          columnsMenuOptions={
            Object.values(Columns).filter((key) => !MANDATORY_COLUMNS.includes(key as Columns)) as Columns[]
          }
        />
      </div>
    </div>
  );
};

export default BlockedNodes;
